@charset "utf-8";
@import url("base.css");

/***************************************************************************
*
* INDEX STYLE
*
***************************************************************************/
html{
    height: 100vh;
    overflow-y: hidden;
}
body {
    background: #FFDA1F;
    font-size: 14px;
    line-height: 1.5;
    font-family: 'Noto Sans JP', sans-serif;
    position: relative;
    height: 100%;
    overflow: hidden;
}

.inner {
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;
}

#header .logo {
    padding-top: 44px;
}
#header .logo {
    display: block;
    padding-left: 25px;
}
#header .logo a{
    /* width: 156px;
    height: 35px; */
    width: 239px;
    display: block;
}
#header .logo a img{
    width: 100%;
    height: auto;
}
#header .logo a.hover:hover{
    opacity: 0.6;
}
#content {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#content .boxImage {
    display: flex;
    justify-content: space-between;
    margin-top: -66px;
}

#content .mainImg {
    width: 484px;
    margin-left: -2px;
}

#content .mainImg img {
    width: 100%;
}

#content .mainBtn {
    width: 188px;
    margin-right: 47px;
    margin-top: 150px;
}

#content .mainBtn a{
    display: block;
    border-radius: 50%;
    position: relative;
}
#content .mainBtn a::after{
    position: absolute;
    content: '';
    background: url(/assets/images/index/asset-3.svg) no-repeat;
    background-size: 100%;
    width: 188px;
    height: 188px;
    border-radius: 50%;
}
#content .mainBtn img {
    width: 100%;
}

#content .wrapContent {
    width: 731px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

#content .boxText {
    text-align: center;
    /* position: absolute; */
    /* top: -20px; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
}
#content .boxText img {
    width: 338px;
}
#content .boxText h3 {
    font-size: 19px;
    font-weight: 400;
    letter-spacing: -2px;
}

#content .boxText h1 {
    font-size: 59px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 1;
}
@media (min-width:769px) {
    #content .mainBtn a:hover::after{
        background: url(/assets/images/index/asset-4.svg) no-repeat;
        background-size: 100%;
    }
}
@media (max-width:768px) {
    .inner{
        width: 100%;
        /* overflow-x: auto; */
    }  
    body {
        width: 1368px;
        overflow-x: auto;
    } 
}